<template>
  <div>
    <div v-if="isMounted" class="vx-row no-gutter items-center justify-center mt-base">
      <div v-if="applicant.success == true" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
        <vx-card class="login-card py-3 px-3">
          <div slot="no-body" class="">
            <div class="vx-col sm:w-full">
              <div class="px-8 pt-8">
                <div v-if="!isNewApplicant" class="vx-card__title mb-4">
                  <h4 class="mb-4">
                    ¡Felicidades! ¡Tu crédito ha sido aprobado!
                  </h4>
                  <p>
                    Para comenzar con el proceso de aceptación de crédito es necesario 
                    crear una cuenta en RedGirasol. 
                    Sigue las indicaciones para generar una contraseña segura.
                  </p>
                </div>
                <div v-else class="vx-card__title mb-4">
                  <h4 class="mb-4">
                    ¡Bienvenido a RedGirasol!
                  </h4>
                  <p>
                    El proveedor <span class="bold">{{ applicant.supplier }}</span> ha creado una solicitud de crédito para ti, 
                    comienza con tu proceso
                    creando una cuenta en RedGirasol. 
                    Sigue las indicaciones para generar una contraseña segura.
                  </p>
                </div>
                <div class="vx-row">
                  <div :class="colClass">
                    <vs-list>
                      <vs-list-item title="Nombre o razón social">{{ applicant.name }}</vs-list-item>
                      <vs-list-item title="Correo electrónico">{{ applicant.email }}</vs-list-item>
                      <vs-list-item title=""></vs-list-item>
                    </vs-list>
                    <!-- <vs-input
                      class="w-full"
                      label="Nombre o Razón Social"
                      name="alias"
                      readonly="true"
                      v-validate="requiredRules"
                      v-model.lazy="applicant.name"
                      :danger="hasError('alias')"
                      :danger-text="errorText('alias')"
                      :success="isSuccess('alias')"
                      icon-pack="feather"
                      :placeholder="requiredPlaceholder"
                    /> -->
                  </div>
                  <!-- <div :class="colClass">
                    <vs-input
                      class="w-full"
                      label="Correo electrónico"
                      name="email"
                      readonly="true"
                      v-validate="emailRules"
                      v-model.lazy="applicant.email"
                      :danger="hasError('email')"
                      :danger-text="errorText('email')"
                      :success="isSuccess('email')"
                      icon-pack="feather"
                      :placeholder="emailPlaceholder"
                    />
                  </div> -->
                </div>
                <div class="vx-row">
                  <div :class="colClass">
                    <label class="vs-input--label">Contraseña *</label>
                    <vx-input-group class="w-full">
                      <vs-input
                        v-model.lazy="applicant.password"
                        v-validate="requiredRules"
                        :danger="hasError('password')"
                        :danger-text="errorText('password')"
                        :success="passwordFieldValid"
                        :type="passInputType"
                        :placeholder="requiredPlaceholder"
                        name="password"/>

                      <template slot="append">
                        <div class="append-text btn-addon" style="height:40px;">
                          <vs-button color="dark" @click="togglePassInputType()" icon-pack="feather" :icon="passInputIcon"></vs-button>
                        </div>
                      </template>
                    </vx-input-group>
                  </div>
                  <div :class="colClass">
                    <label class="vs-input--label">Confirma la contraseña *</label>
                    <vx-input-group class="w-full">
                      <vs-input
                        onpaste="return false;"
                        v-model.lazy="applicant.confirmed_password"
                        v-validate="requiredRules"
                        :danger="hasError('confirmed_password')"
                        :danger-text="errorText('confirmed_password')"
                        :success="confirmPasswordFieldValid"
                        :type="confirmPassInputType"
                        :placeholder="requiredPlaceholder"
                        name="confirmed_password"/>

                      <template slot="append">
                        <div class="append-text btn-addon" style="height:40px;">
                          <vs-button color="dark" @click="toggleConfirmPassInputType()" icon-pack="feather" :icon="confirmPassInputIcon"></vs-button>
                        </div>
                      </template>
                    </vx-input-group>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <p class="mb-3">
                      <strong>La contraseña debe contener:</strong>
                    </p>
                  </div>
                  <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full">
                    <p class="mb-5">
                      <feather-icon :svgClasses="`${colorForCriteria(0)} h-4 w-4`" class="p-2" :icon="iconForCriteria(0)" />Una letra minúscula <br>
                      <feather-icon :svgClasses="`${colorForCriteria(1)} h-4 w-4`" class="p-2" :icon="iconForCriteria(1)" />Una letra mayúscula <br>
                      <feather-icon :svgClasses="`${colorForCriteria(2)} h-4 w-4`" class="p-2" :icon="iconForCriteria(2)" />Un número <br>
                    </p>
                  </div>
                  <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full">
                    <p class="mb-5">
                      <feather-icon :svgClasses="`${colorForCriteria(3)} h-4 w-4`" class="p-2" :icon="iconForCriteria(3)" />Un símbolo <br>
                      <feather-icon :svgClasses="`${colorForCriteria(4)} h-4 w-4`" class="p-2" :icon="iconForCriteria(4)" />8 caracteres mínimo <br>
                      <feather-icon :svgClasses="`${colorForCriteria(5)} h-4 w-4`" class="p-2" :icon="iconForCriteria(5)" />Sin letras ni números consecutivos <br>
                    </p>
                  </div>
                </div>
                <div class="vx-row mt-3 mb-3">
                  <div class="vx-col w-full mb-3">
                    <div class="checkbox-display">
                      <vs-checkbox
                        name="terms"
                        v-model.lazy="contracts.allContracts">
                      </vs-checkbox>
                      <p>Al hacer clic en “Crear cuenta” firmas electrónicamente nuestro 
                        <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(comisionMercantilUrl)">Contrato de Comisión Mercantil</a> (lo cual nos permite levantar fondos para tu proyecto), así como los 
                        <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(terminosCondicionesUrl)"> Términos y Condiciones</a>, y el 
                        <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(avisoPrivacidadUrl)">Aviso de Privacidad</a>.</p>
                    </div>
                  </div>
                </div>
                <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
                  <span class="font-regular">{{errorMssg}}</span>
                </vs-alert>
                <vs-button
                  color="primary"
                  class="mr-5 mt-4"
                  @click="saveData"
                  >Crear cuenta</vs-button>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
      <div v-else class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
        <vx-card style="padding: 5rem;" class="text-center w-full">
            <img src="@/assets/images/figs/fig9.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">        
            <h3 class="mb-6 mt-6">Ya has activado tu cuenta previamente. Inicia sesión con tu correo electrónico y contraseña.</h3>
            <vs-button @click="goToHomePage()"><strong>Inicia sesión</strong></vs-button>
        </vx-card>
      </div>      
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
export default {
  name: "CreateAccount",
  mixins: [inputHelper],
  props: ["token"],
  data(){
    return {
      isMounted: false,
      colClass: "vx-col w-full mb-5",
      requiredRules: 'required',
      requiredPlaceholder: '(Requerido)',
      emailRules: 'required|email',
      emailPlaceholder: '(Requerido) Ingresa una dirección de correo válida',
      applicantContractUrl: 'contrato-de-comision-mercantil-cliente',
      optionalRules: '',
      passInputPassMode: true,
      confirmPassInputPassMode: true,
      errorMssg: null,
      criteria: [false, false, false, false, false, false, false],
      passwordError: null,
      contracts: {
        allContracts: null,
      },
      applicant: {
        id: null,
        name: null,
        email: null,
        preauth: null,
        user_id: null,
        password: null,
        confirmed_password: null,
        finance: null,
        success: false
      },
      flowType: null
    }
  },
  computed: {
    passInputType(){
      return this.passInputPassMode ? "password" : textType;
    },
    passInputIcon(){
      return this.passInputPassMode ? iconEye : iconEyeOff;
    },
    confirmPassInputType(){
      return this.confirmPassInputPassMode ? "password" : textType;
    },
    confirmPassInputIcon(){
      return this.confirmPassInputPassMode ? iconEye : iconEyeOff;
    },
    passwordFieldValid(){
      return this.testPassword(this.applicant.password);
    },
    confirmPasswordFieldValid(){
      return this.applicant.password === this.applicant.confirmed_password && this.applicant.confirmed_password !=  null;
    },
    terminosCondicionesUrl(){
      return `${this.ApiDomain}/terminos-y-condiciones`;
    },
    avisoPrivacidadUrl(){
      return `${this.ApiDomain}/aviso-de-privacidad`;
    },
    comisionMercantilUrl(){
        return `${this.ApiDomain}/${this.applicantContractUrl}`;
    },
    isNewApplicant() {
      return this.flowType == 'new_applicant';
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    this.getParams();
    await this.getApplicant();
    this.showLoading(false);
    this.isMounted = true;
  },
  methods: {
    getParams(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.flowType = urlParams.get('type');
    },
    async getApplicant(){
      try {
        const res = await this.publicApiGet(`api/v1/applicant/create/${this.token}`);
        this.applicant = res.data;
      }
      catch (e){
        this.showLoading(false)
        // await this.$router.replace({name: 'login'});
        this.warn(e);
      }
    },
    async validateForm(){
      this.errorMssg = null;
      let success = false;
      let r1 = await this.$validator.validateAll();
      if(r1){
        success = this.validatePassword();
      }
      return success;
    },
    async saveData(){
      this.showLoading(true);
      this.errorMssg = null;
      const valid = await this.validateForm();
      let complete = false;
      if(valid){
        const mcontracts = this.validateContracts();
        if(mcontracts){
          complete = true;
        }
      }
      if(!complete) {
        this.showLoading(false);
        this.errorNotif("Campos pendientes", "Por favor completa los campos pendientes para poder continuar.", 5000);
      }else{
        try {
          let data = {
            password_user: this.applicant.password,
            email_user: this.applicant.email,
            preauth: this.applicant.preauth,
            user_id: this.applicant.user_id,
            metadata: {
              account: {id: this.applicant.id, name: "client"}
            }
          }
          await this.publicApiPost('api/v1/applicant/create',data);
          this.showLoading(false);
          await this.doLogin();
        } catch (e) {
          this.showLoading(false);
          this.failedOperationNotif("Error creando cuenta");
        }
      }
    },
    async doLogin(){
        const payload = { email: this.applicant.email, password: this.applicant.password, emailRemember: false, rememberme: false,  twoFactorCode: "" };
        const res = await this.publicApiPost('api/v1/auth/loginViaApp', payload);
        const token = res.data.access_token;
        // almacenar el token en auth (sessionStorage)
        this.setStateAccessToken(token);
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.$store.state.isUnauthenticated = false;
        const userRes = await this.getUserInfo("client");
        this.showFormPrompt = false;
        this.showLoading(false);
        // redirigir a la vista original a la que el usuario intento entrar
        await this.$router.replace({name: 'home'});
    },
    colorForCriteria(idx){
      return this.criteria[idx] ? 'text-success' : 'text-danger';
    },
    iconForCriteria(idx){
      return this.criteria[idx] ? 'CheckCircleIcon' : 'CircleIcon';
    },
    completePasswordForm(){
      return this.applicant.password != null && this.applicant.password.length >= 8;
    },
    completeConfirmPassword(){
      return this.applicant.confirmed_password != null && this.applicant.confirmed_password.length >= 8;
    },
    validatePassword(){
      let success = false;
      if(this.applicant.password !== this.applicant.confirmed_password){
        this.errorMssg = "Las contraseñas no coinciden";
      }
      else {
        if(this.testPassword(this.applicant.password)){
          success = true;
        }
        else {
          this.errorMssg = "La contraseña no cumple los requerimientos";
        }
      }
      return success;
    },
    testPassword(pass){
      if(pass === null || pass === undefined){
        this.criteria[0] = false;
        this.criteria[1] = false;
        this.criteria[2] = false;
        this.criteria[3] = false;
        this.criteria[4] = false;
        this.criteria[5] = false;
        this.criteria[6] = false;
        return false;
      }
      //let pass = this.pw1
      const number = /.*[0-9].*/
      const lower = /.*[a-z].*/
      const upper = /.*[A-Z].*/
      const special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
      const consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
        '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
        'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
        '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
        '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
        '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']

      this.criteria[0] = lower.test(pass);
      this.criteria[1] = upper.test(pass);
      this.criteria[2] = number.test(pass);
      this.criteria[3] = special.test(pass);
      this.criteria[4] = pass.length > 7;
      let no_consecutive = true
      for(let i = 0; i < consecutive.length; i ++){
        if(pass.toLowerCase().includes(consecutive[i])){
          no_consecutive = false
        }
      }
      this.criteria[5] = no_consecutive

      return (this.criteria[0] && this.criteria[1] && this.criteria[2] && this.criteria[3] && this.criteria[4] && this.criteria[5]);
    },
    togglePassInputType(){
      this.passInputPassMode = !this.passInputPassMode;
    },
    toggleConfirmPassInputType(){
      this.confirmPassInputPassMode = !this.confirmPassInputPassMode;
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
    validateContracts(){
      if(this.contracts.allContracts !== true){
        this.errorMssg = "Debes aceptar los Términos y Condiciones, el Aviso de Privacidad y el Contrato de Comisión Mercantíl.";
        return false;
      }
      return true;
    },
    resetForm(){
    },
    async goToHomePage(){
        await this.$router.push({name: 'login'});
    },
  }
}
</script>

<style lang="css" scoped>
.checkbox-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
